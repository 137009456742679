import React from "react";
import {
  Box,
  ButtonBase,
  Dialog,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";

import { getSrc } from "gatsby-plugin-image";
import { graphql } from "gatsby";

import Page from "../Layout/Page";
import { QueryResult, Artwork } from "../utils/types";
import getGatsbyImage from "../utils/getGatsbyImage";
import { sizes } from "../utils/constants";
import { myTheme } from "../utils/Theme";
import { Helmet } from "react-helmet";
import { getSeoLinks, getSeoMetas } from "../components/SeoHelper";

export const query = graphql`
  {
    aube: allImageSharp(filter: { original: { src: { regex: "/aube/i" } } }) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    blood: allImageSharp(filter: { original: { src: { regex: "/blood/i" } } }) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    frighten: allImageSharp(
      filter: { original: { src: { regex: "/frighten/i" } } }
    ) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    laNina: allImageSharp(
      filter: { original: { src: { regex: "/La nina/i" } } }
    ) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    love: allImageSharp(filter: { original: { src: { regex: "/Love/i" } } }) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    obsession: allImageSharp(
      filter: { original: { src: { regex: "/obsession/i" } } }
    ) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    rasputin: allImageSharp(
      filter: { original: { src: { regex: "/rasputin/i" } } }
    ) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    therian: allImageSharp(
      filter: { original: { src: { regex: "/therian/i" } } }
    ) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
    uglyGirl: allImageSharp(
      filter: { original: { src: { regex: "/ugly girl/i" } } }
    ) {
      edges {
        node {
          gatsbyImageData
          original {
            src
          }
        }
      }
    }
  }
`;

function getArtworksFromData(data: {
  aube: QueryResult;
  blood: QueryResult;
  frighten: QueryResult;
  laNina: QueryResult;
  love: QueryResult;
  obsession: QueryResult;
  rasputin: QueryResult;
  therian: QueryResult;
  uglyGirl: QueryResult;
}): Artwork[] {
  return [
    {
      src: getSrc(getGatsbyImage(data.blood)) || "",
      title: "Blood",
      description: "Acrylics on panel, FEELINGS, 2022",
    },
    {
      src: getSrc(getGatsbyImage(data.frighten)) || "",
      title: "Frighten",
      description: "Acrylics on panel,Violence, 2016",
    },
    {
      src: getSrc(getGatsbyImage(data.obsession)) || "",
      title: "Obsession (digital version)",
      description: "FEELINGS, 2022",
    },
    {
      src: getSrc(getGatsbyImage(data.laNina)) || "",
      title: "La Niña",
      description: "Acrylics on canvas, 2016",
    },
    {
      src: getSrc(getGatsbyImage(data.love)) || "",
      title: "Love",
      description: "Oils on panel, FEELINGS, 2022",
    },
    {
      src: getSrc(getGatsbyImage(data.uglyGirl)) || "",
      title: "Ugly Girl",
      description: "Acrylics on panel, FEELINGS, 2022",
    },
    {
      src: getSrc(getGatsbyImage(data.rasputin)) || "",
      title: "Rasputin",
      description: "Acrylics on cardboard, 2016",
    },
    {
      src: getSrc(getGatsbyImage(data.aube)) || "",
      title: "Aube",
      description: "Oils on panel, FEELINGS, 2022",
    },
    {
      src: getSrc(getGatsbyImage(data.therian)) || "",
      title: "Therian (restored)",
      description: "Actylics on canvas, the Three Demons, 2021",
    },
  ];
}
const ArtworkItem = ({ artwork }: { artwork: Artwork }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  return (
    <ImageListItem key={artwork.src}>
      <ButtonBase onClick={() => setIsModalOpen(true)}>
        <img
          src={artwork.src}
          style={{ maxHeight: "100%", maxWidth: "100%" }}
        />
      </ButtonBase>
      <Dialog
        maxWidth="xl"
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <img
          src={artwork.src}
          style={{
            display: "block",
            maxWidth: "100%",
            maxHeight: `calc(100vh - ${myTheme.spacing(8)})`,
            color: "red",
          }}
        />
        <Box
          padding={sizes.s}
          position="absolute"
          bottom={0}
          padding={myTheme.spacing(1)}
          sx={{
            background:
              "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
              "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
          }}
        >
          <Typography id="modal-modal-title" component="h2" color="white">
            {artwork.title}
          </Typography>
          <Typography id="modal-modal-description" color="white">
            {artwork.description}
          </Typography>
        </Box>
      </Dialog>
    </ImageListItem>
  );
};
const IndexPage = ({ data }: any) => {
  const artworks = getArtworksFromData(data);
  return (
    <Page title={"paintings"}>
      <Helmet
        title="Paintings"
        titleTemplate="%s · Lisa Angrino"
        meta={getSeoMetas({
          title: "Paintings",
          path: "paintings",
          description:
            "Lisa Angrino is a visual artist and philosopher from Colombia. Discover her paintings and drawings, more about her story and how to contact her. While she experiments with materials and media, her thematic focus has always been the interpretation, translation and transmission of dreams, feelings, and the connection between the real and the unreal.",
        })}
        link={getSeoLinks({ path: "paintings" })}
      />
      <ImageList
        variant="masonry"
        sx={{
          columnCount: {
            xs: "1 !important",
            sm: "2 !important",
            md: "3 !important",
            lg: "3 !important",
            xl: "3 !important",
          },
        }}
        gap={8}
      >
        {artworks.map((item: Artwork) => (
          <ArtworkItem key={item.src} artwork={item} />
        ))}
      </ImageList>
    </Page>
  );
};

export default IndexPage;
